import { useEffect, useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";

const mkError = (msg) => ({
	type: 'error',
	msg,
})

const mkResult = (url) => ({
	type: 'result',
	url,
})

const mkRequest = (domain, username, password) => ({
	type: 'request',
	domain, username, password
})

const mkRequesting = () => ({
	type: 'requesting',
})

const mkInit = () => ({
	type: 'init',
})

const App = () => {
	const [state, setState] = useState(mkInit())

	useEffect(() => {
		if (state.type === 'request') {
			console.log(`starting request`)
			setState(mkRequesting())

			fetch('/.netlify/functions/login', {
				method: 'POST',
				body: JSON.stringify(state)
			}).then(result => {
				if (result.status === 200) {
					result.json().then(({ url }) => {
						if (!url) {
							setState(mkError(`url property of payload unexpectedly empty`))
						} else {
							setState(mkResult(url))
						}
					}).catch(err => mkError(err.toString()))
				} else if (result.status === 403) {
					setState(mkError(`unknown username and/or password`))
				} else {
					setState(mkError(`unexpected status code ${result.status}`))
				}
			}).catch(error => {
				console.log(error)
				setState(mkError(error))
			})
		}

		if (state.type === 'result') {
			console.log(`redirecting to: ${state.url}`)
			location.href = state.url
		}

	}, [state])

	const doLogin = (domain, username, password) => {
		console.log(`Logging in with:`)
		console.log(`- domain: ${domain}`)
		console.log(`- username: ${username}`)
		console.log(`- password: ${password}`)

		console.log('trying to log in')
		setState(mkRequest(domain, username, password))

		//setError('Unknown username and/or password.')
	}

	const doTryAgain = () => {
		setState(mkInit())
	}

	return (
		<section className="section">
			<div className="container">
				<div className="columns">
					<div className="column is-5-desktop">
						<h1 className="title">
							Actyx Console
						</h1>
						{
							state.type === 'error'
								? <Error msg={state.msg} doTryAgain={doTryAgain} />
								: state.type === 'init'
									? <Login doLogin={doLogin} />
									: state.type === 'requesting' || state.type === 'result'
										? <Loading />
										: <p>Unknown state</p>
						}
					</div>
				</div>
			</div>
		</section>
	)
}


const Loading = () => (
	<p>Logging in...</p>
)

const Error = ({ msg, doTryAgain }) => (
	<>
		<p className="has-text-danger">Error: {msg}</p>
		<button onClick={doTryAgain} className="button mt-4">Try again</button>
	</>


)

const Login = ({ doLogin }) => {

	const [domain, setDomain] = useState('')
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')

	const canLogin = domain && username && password;

	const login = () => {
		if (domain && username && password) {
			doLogin(domain, username, password)
		}
	}

	return (
		<div>
			<div className="field">
				<label className="label">Domain</label>
				<div className="control">
					<input className="input" type="text" placeholder="Your login domain"
						value={domain} onChange={e => setDomain(e.target.value)} />
				</div>
			</div>
			<div className="field">
				<label className="label">Username</label>
				<div className="control">
					<input className="input" type="text" placeholder="Your email or username"
						value={username} onChange={e => setUsername(e.target.value)} />
				</div>
			</div>
			<div className="field">
				<label className="label">Password</label>
				<div className="control">
					<input className="input" type="password" placeholder="Your password"
						value={password} onChange={e => setPassword(e.target.value)} />
				</div>
			</div>
			<button onClick={login} disabled={!canLogin} className="button">Login</button>

		</div>
	)
}

ReactDOM.render(<App />, document.getElementById("app"));
